import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentUserData: [],
    specifiedMenu: undefined,
    backgroundImg: "",
  },
  getters: {
    get_user_info: (state) => {
      return state.currentUserData;
    },

    get_specified_route_details: (state) => {
      return state.specifiedMenu;
    },

    get_background_image: (state) => {
      return state.backgroundImg;
    },
  },
  mutations: {
    SET_USER_INFO(state, val) {
      state.currentUserData = val;
    },

    SET_ROUTE_TO_SPECIFIED_MENU(state, val) {
      state.specifiedMenu = val;
    },

    SET_APP_BACKGROUND_IMG(state, imgFile) {
      state.backgroundImg = imgFile;
    },
  },
  plugins: [createPersistedState()],
  actions: {},
  modules: {},
});
