import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/croppa",
    name: "croppaPage",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/views/ChildPages/VueCroppaLogic.vue"
      ),
  },
  {
    path: "/",
    name: "loginPage",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/MainPages/LoginPage.vue"),
  },
  {
    path: "/landingpage",
    name: "landingpage",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/views/MainPages/LandingPage.vue"
      ),
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/ChildPages/Dashboard.vue"
          ),
      },
      {
        path: "/admins",
        name: "Admins",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/ChildPages/AdminPage.vue"
          ),
      },
      {
        path: "/coaches",
        name: "Coaches",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/ChildPages/CoachPage.vue"
          ),
      },
      {
        path: "/sports",
        name: "Sports",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/ChildPages/SportsPage.vue"
          ),
      },
      {
        path: "/coachingvideos",
        name: "Coaching Videos",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/views/ChildPages/CabinetPage.vue"
          ),
      },
      {
        // path: "/coach/:coachName",
        path: `/coaches/:coachName`,
        name: "Coach Infos",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "about" */ "@/components/Coaches/ChildPages/CoachInfo.vue"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
